<script>
	// borrowed from https://kellenmace.com/blog/lite-youtube-embed-for-svelte

	import Image from '$lib/Components/Layout/Image/Image.svelte';

	export let videoId = '';
	export let src = '';
	export let playLabel = 'Play';
	export let params = '';
	export let posterImageSrc = null;
	export let posterloading = 'lazy';
	export let title = '';
	let url;
	if (src && !videoId) {
		url = new URL(src);
		videoId =
			url.searchParams.get('v') ??
			url.pathname.slice(url.pathname.lastIndexOf('/') + 1);
	}

	const posterImage = posterImageSrc ?? {
		sources: {
			webp: `https://i.ytimg.com/vi_webp/${videoId}/hqdefault.webp`,
		},
		img: {
			src: `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`,
			h: 360,
			w: 480,
		},
	};
	let activated = false;
	let hovered = false;

	function videoIdChanged() {
		activated = false;
	}

	$: videoIdChanged(videoId);
	$: computedParams = (() => {
		const p = new URLSearchParams(params);
		p.append('autoplay', '1');
		return p.toString();
	})();

	function focus(node) {
		node.focus();
	}
</script>

<svelte:head>
	{#if hovered}
		<link rel="preconnect" href="https://www.youtube.com" />
		<link rel="preconnect" href="https://www.google.com" />
	{/if}
</svelte:head>

<div
	class={[
		'lite-youtube mb-4 flex aspect-video w-full max-w-[var(--image-width)] items-center overflow-hidden last:mb-0 max-md:!col-span-full',
		$$props.class ?? '',
	].join(' ')}
	class:lite-youtube-activated={activated}
	on:pointerover|once={() => (hovered = true)}
	on:click={() => (activated = true)}
	on:keypress={() => (activated = true)}
	role="button"
	tabindex="0"
>
	{#key videoId}
		<Image
			src={posterImage}
			alt={title ?? playLabel}
			loading={posterloading}
			class="full-width"
		/>
	{/key}
	<button type="button" class="lite-youtube-playbtn" aria-label={playLabel} />
	{#if activated}
		<iframe
			width="560"
			height="315"
			title={playLabel}
			allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
			allowfullscreen
			src="https://www.youtube.com/embed/{encodeURIComponent(
				videoId,
			)}?{computedParams}"
			use:focus
		/>
	{/if}
</div>

<style global>
	:global(.lite-youtube) {
		background-color: #000000;
		position: relative;
		contain: content;
		cursor: pointer;
	}
	/* gradient */
	:global(.lite-youtube::before) {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
		background-position: top;
		background-repeat: repeat-x;
		height: 60px;
		padding-bottom: 50px;
		width: 100%;
		transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
		box-sizing: unset;
		z-index: 1;
	}
	/* responsive iframe with a 16:9 aspect ratio
    thanks https://css-tricks.com/responsive-iframes/
	*/
	:global(.lite-youtube::after) {
		content: '';
		display: block;
		padding-bottom: calc(100% / (16 / 9));
	}
	:global(.lite-youtube) > :global(iframe) {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		border: 0;
	}
	/* poster */
	:global(.lite-youtube-poster) {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		object-fit: contain;
	}
	/* play button */
	:global(.lite-youtube) > :global(.lite-youtube-playbtn) {
		width: 68px;
		height: 48px;
		position: absolute;
		cursor: pointer;
		transform: translate3d(-50%, -50%, 0);
		top: 50%;
		left: 50%;
		z-index: 1;
		background-color: transparent;
		/* YT's actual play button svg */
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 68 48"><path fill="%23f00" fill-opacity="0.8" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"></path><path d="M 45,24 27,14 27,34" fill="%23fff"></path></svg>');
		filter: grayscale(100%);
		transition: filter 0.1s cubic-bezier(0, 0, 0.2, 1);
		border: none;
		outline: 0;
	}
	:global(.lite-youtube:hover) > :global(.lite-youtube-playbtn),
	:global(.lite-youtube) :global(.lite-youtube-playbtn:focus) {
		filter: none;
	}
	/* Post-click styles */
	:global(.lite-youtube.lite-youtube-activated) {
		cursor: unset;
	}
	:global(.lite-youtube.lite-youtube-activated::before),
	:global(.lite-youtube.lite-youtube-activated)
		> :global(.lite-youtube-playbtn) {
		opacity: 0;
		pointer-events: none;
	}
</style>
